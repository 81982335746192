import styled from "styled-components";
import { DragOverlay } from "@dnd-kit/core";

export const StyledDragOverlay = styled(DragOverlay)`
  background-color: #c7e0c0;
  border: 1px solid #778693;
  padding: 0.3em;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
`;
