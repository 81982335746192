import React, { useState, useContext } from "react";
import FormInput from "../../reusable-components/form-input/FormInput";
import CustomTitle from "../../reusable-components/custom-title/CustomTitle";
import CustomButton from "../../reusable-components/custom-button/CustomButton";
import { StyledForm, StyledButtonWr } from "./tableForms.styles";
import { TablesContext } from "../../../providers/tables/tables.provider";
import { useAuth } from "../../../hooks/useAuth";
import {
  deleteTableDoc,
  updateTableDoc,
} from "../../../firebase/firebase.utils";

const submittingDefaultState = {
  save: false,
  delete: false,
};

const EditTableForm = ({ table, setShowEditModal }) => {
  const { seats, tableNumber } = table;

  const { editTable, deleteTable } = useContext(TablesContext);
  const customAuth = useAuth();

  const [formData, setFormData] = useState({
    seats: seats,
    tableNumber: tableNumber,
  });
  const [isSubmitting, setIsSubmitting] = useState(submittingDefaultState);

  const handleChange = (event) => {
    const { value, name } = event.target;

    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting({ ...isSubmitting, save: true });
    try {
      await updateTableDoc(customAuth.user.uid, {
        ...table,
        ...formData,
      });

      editTable({ ...table, ...formData });
      setShowEditModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnClick = async () => {
    setIsSubmitting({ ...isSubmitting, delete: true });
    try {
      await deleteTableDoc(customAuth.user.uid, table);
      setShowEditModal(false);
      deleteTable(table);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <StyledForm onSubmit={handleSubmit}>
      <CustomTitle center>Edit table</CustomTitle>
      <FormInput
        type="number"
        label="Table number"
        id="tableNumber"
        onChange={handleChange}
        value={formData.tableNumber}
        required
      />
      <FormInput
        type="number"
        label="Count of seats"
        id="seats"
        onChange={handleChange}
        value={formData.seats}
        required
      />
      <StyledButtonWr>
        <CustomButton isLoading={isSubmitting.save}>Save</CustomButton>
        <CustomButton
          type="button"
          onClick={handleOnClick}
          customStyle="secondary"
          isLoading={isSubmitting.delete}>
          Delete
        </CustomButton>
      </StyledButtonWr>
    </StyledForm>
  );
};

export default EditTableForm;
