import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

const secondaryStyle = css`
  background-color: #fff;
  color: ${(props) => props.theme.colors.red};
  &:focus,
  &:hover {
    background-color: ${(props) => props.theme.colors.red};
    color: #fff;
  }
`;

const iconStyle = css`
  background-color: transparent;
  border: none;
  max-width: unset;
  width: auto;
  height: auto;
  padding: 7px;
  font-size: 0;
  svg {
    width: 25px;
    height: 25px;
    path {
      fill: black;
    }
  }
  &:focus,
  &:hover {
    background-color: transparent;
    svg {
      path {
        fill: #398750;
      }
    }
  }
`;

const baseStyle = css`
  display: inline-block;
  border: 1px solid ${(props) => props.theme.colors.red};
  font-size: 18px;
  width: 100%;
  height: 45px;
  max-width: 130px;
  padding: 0.6em;
  cursor: pointer;
  transition: background-color 250ms ease-in-out;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  background-color: ${(props) => props.theme.colors.red};
  color: #ffffff;
  &:focus,
  &:hover {
    background-color: #fff;
    color: ${(props) => props.theme.colors.red};
  }

  &:disabled {
    cursor: not-allowed;
    pointer-events: none;
  }

  ${(props) => props.$customStyle === "secondary" && secondaryStyle}
  ${(props) => props.$customStyle === "icon" && iconStyle}
`;

export const StyledButton = styled.button`
  ${baseStyle}
`;

export const StyledLink = styled(Link)`
  ${baseStyle}
`;
