import styled from "styled-components";
import Container from "../../reusable-components/container/Container";

export const StyledContainer = styled(Container)`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
