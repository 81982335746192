import styled from "styled-components";

export const StyledDiv = styled.div`
  p {
    text-align: center;
    margin-bottom: 2em;
  }
  form > div {
    margin-bottom: 1em;
    label {
      display: inline-block;
      width: 35%;
      text-align: right;
    }
  }
`;

export const StyledButtonWr = styled.div`
  margin: 2em 1em 2em calc(35% + 0.7em);
  display: flex;
  button:first-child {
    margin-right: 1em;
  }
`;
