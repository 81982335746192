import styled from "styled-components";

export const StyledModal = styled.div`
  background: white;
  width: 100%;
  max-width: ${({ $width }) => ($width ? $width : "950px")};
  height: auto;
  border-radius: 15px;
  padding: 2rem;
  position: relative;
  > button {
    position: absolute;
    right: 5px;
    top: 5px;
    padding: 0;
    border-radius: 50%;
    path {
      fill: ${(props) => props.theme.colors.red};
    }
    &:hover,
    &:focus {
      path {
        fill: ${(props) => props.theme.colors.darkGrey};
      }
    }
  }
  table button {
    svg {
      height: 100%;
      width: 20px;
      height: 20px;
    }
    path {
      fill: ${(props) => props.theme.colors.red};
    }
    &:hover,
    &:focus {
      path {
        fill: ${(props) => props.theme.colors.darkGrey};
      }
    }
  }
`;

export const StyledModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const StyledModalBody = styled.div`
  padding-top: 10px;
`;
