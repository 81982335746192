import React, { useState } from "react";
import { useHistory } from "react-router";
import { useAuth } from "../../../hooks/useAuth";
import { StyledCustomButton } from "./MyAccountNav.styles";

const MyAccountNav = () => {
  const [isLoading, setIsLoading] = useState(false);
  const customAuth = useAuth();
  let history = useHistory();

  const handleClick = async () => {
    setIsLoading(true);
    try {
      await customAuth.logOut();
      history.push("/login");
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <StyledCustomButton
      handleClick={handleClick}
      customStyle="secondary"
      isLoading={isLoading}>
      Log Out
    </StyledCustomButton>
  );
};

export default MyAccountNav;
