import styled from "styled-components";

export const StyledTable = styled.table`
  width: 100%;
  text-align: left;
  margin: 2em 0;
  svg {
    width: 20px;
    path {
      fill: ${(props) => props.theme.colors.red};
    }
  }
  button:hover,
  button:focus {
    svg {
      path {
        fill: ${(props) => props.theme.colors.darkGrey};
      }
    }
  }
`;
