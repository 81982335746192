import React from "react";
import { useDroppable } from "@dnd-kit/core";
import { StyledDiv } from "./Droppable.styles";

const Droppable = ({ id, disabled, children, ...otherProps }) => {
  const { isOver, setNodeRef } = useDroppable({
    id: `droppable-${id}`,
    data: {
      position: id,
    },
  });

  return (
    <StyledDiv
      ref={setNodeRef}
      $isOver={disabled ? null : isOver}
      {...otherProps}>
      {children}
    </StyledDiv>
  );
};

export default Droppable;
