import styled, { css } from "styled-components";

export const StyledDiv = styled.div`
  display: flex;
  max-width: 100%;
  height: 100%;
  background-color: #c7e0c0;
  border: 2px solid #778693;
  border-radius: 5px;
  position: relative;
  cursor: ${({ $isStatic }) => ($isStatic ? "pointer" : "grab")};
  div {
    padding: 0.3em;
  }
  span {
    display: flex;
    position: absolute;
    bottom: 0.3em;
    right: 0.3em;
    svg {
      width: 15px;
      margin-right: 0.5em;
    }
  }
  ${({ $isDragging }) => {
    return (
      $isDragging &&
      css`
        opacity: 0.5;
      `
    );
  }}
`;
