import styled, { css } from "styled-components";

export const StyledDiv = styled.div`
  width: 100%;
  height: 75px;
  border: 1px solid rgba(128, 128, 125, 0.15);
  background: white;
  ${({ $isOver }) =>
    $isOver &&
    css`
      border: 2px solid #778693;
      border-radius: 5px;
      background-color: #c7e0c0;
      opacity: 0.5;
    `}
`;
