import styled, { css } from "styled-components";

export const StyledSpinnerWr = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${({ $pageHeight }) => ($pageHeight ? "100vh" : "100%")};

  > div {
    margin: 0 auto;
    font-size: ${({ $size }) => $size / 2.5}px;
    position: relative;
    text-indent: -9999em;
    border-top: 0.4em solid rgba(255, 255, 255, 0.2);
    border-right: 0.4em solid rgba(255, 255, 255, 0.2);
    border-bottom: 0.4em solid rgba(255, 255, 255, 0.2);
    border-left: 0.4em solid #ffffff;

    ${({ $theme }) =>
      $theme === "dark" &&
      css`
        border-top: 0.4em solid rgba(218, 77, 70, 0.2);
        border-right: 0.4em solid rgba(218, 77, 70, 0.2);
        border-bottom: 0.4em solid rgba(218, 77, 70, 0.2);
        border-left: 0.4em solid rgba(218, 77, 70, 1);
      `}

    transform: translateZ(0);
    animation: load8 1.1s infinite linear;
    border-radius: 50%;
    width: ${({ $size }) => `${$size}px`};
    height: ${({ $size }) => `${$size}px`};

    &:after {
      border-radius: 50%;
      width: ${({ $size }) => `${$size}px`};
      height: ${({ $size }) => `${$size}px`};
    }

    @keyframes load8 {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;
