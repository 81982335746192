import React, { useContext, useState } from "react";
import { TablesContext } from "../../../providers/tables/tables.provider";
import CustomTitle from "../../reusable-components/custom-title/CustomTitle";
import DisplayGrid from "../../reusable-components/display-grid/DisplayGrid";
import Droppable from "../../reusable-components/droppable/Droppable";
import DraggableTable from "../draggable-table/DraggableTable";
import Modal from "../../reusable-components/modal/Modal";
import { hasArrayObjectAttributeValue } from "../../../utils/utils";
import ReservationsModalContent from "../../main/forms/ReservationsModalContent";
import AddReservationModalContent from "../../main/forms/AddReservationModalContent";
import EditReservationModalContent from "../../main/forms/EditReservationModalContent";
import RemoveReservationModalContent from "../../main/forms/RemoveReservationModalContent";
import { ReservationsContext } from "../../../providers/reservations/Reservations.provider";
import LayoutWr from "../../reusable-components/layout-wr/LayoutWr";

const Reservations = () => {
  const { modalSection, changeModalSection } = useContext(ReservationsContext);
  const { tables } = useContext(TablesContext);

  const [showReservationsModal, setShowReservationsModal] = useState(false);
  const [selectedTable, setSelectedTable] = useState(null);

  const handleClick = (droppableId) => {
    if (!hasArrayObjectAttributeValue(tables, "tablePosition", droppableId))
      return;

    const selectedTable = tables.filter(
      (table) => table.tablePosition === droppableId
    );

    setSelectedTable(...selectedTable);
    setShowReservationsModal(true);
  };

  const handleModalClose = () => {
    setShowReservationsModal(false);
    changeModalSection("reservations");
    setSelectedTable(null);
  };

  return (
    <LayoutWr>
      <CustomTitle>Reservations</CustomTitle>
      <DisplayGrid rows={15} cols={10}>
        {Array(150)
          .fill(null)
          .map((_, index) => (
            <Droppable
              key={index}
              id={index}
              disabled={true}
              onClick={() => handleClick(index)}>
              {tables.map(
                (table) =>
                  table.tablePosition === index && (
                    <DraggableTable
                      key={table.tableNumber}
                      table={{ ...table }}
                      isStatic={true}
                    />
                  )
              )}
            </Droppable>
          ))}
      </DisplayGrid>
      <Modal
        onClose={handleModalClose}
        show={showReservationsModal}
        width="750px">
        {modalSection === "reservations" && (
          <ReservationsModalContent table={selectedTable} />
        )}
        {modalSection === "addReservation" && (
          <AddReservationModalContent table={selectedTable} />
        )}
        {modalSection === "editReservation" && <EditReservationModalContent />}
        {modalSection === "deleteReservation" && (
          <RemoveReservationModalContent />
        )}
      </Modal>
    </LayoutWr>
  );
};

export default Reservations;
