import styled from "styled-components";

export const StyledForm = styled.form`
  label {
    display: inline-block;
    min-width: 150px;
    text-align: right;
  }
  input {
    margin-bottom: 1em;
  }
  svg {
    path {
      fill: ${(props) => props.theme.colors.red};
    }
  }
  button:focus,
  button:hover {
    svg {
      path {
        fill: ${(props) => props.theme.colors.darkGrey};
      }
    }
  }
`;

export const StyledButtonWr = styled.div`
  margin-left: calc(149px + 0.7em);
  display: flex;
`;

export const DateInputWr = styled.div`
  display: flex;
  align-items: flex-start;
  label {
    margin-top: 0.7em;
    margin-right: 0.7em;
  }
  input {
    font-size: 1rem;
    padding: 0.5em 0.8em;
  }
`;

export const StyledSpinnerWr = styled.div`
  min-height: 200px;
  display: flex;
  align-items: center;
`;
