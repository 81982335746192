import styled from "styled-components";

export const StyledDraggableTable = styled.div`
  position: relative;
  height: 100%;
  button {
    position: absolute;
    bottom: 0;
  }
`;
