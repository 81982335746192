import React from "react";

import Card from "../../reusable-components/card/Card";
import Login from "../../reusable-components/login/Login";
import LoginWrapper from "../../reusable-components/login-wrapper/LoginWrapper";

const LoginPage = () => {
  return (
    <LoginWrapper>
      <Card title="Login">
        <Login />
      </Card>
    </LoginWrapper>
  );
};

export default LoginPage;
