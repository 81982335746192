import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colors.red};
  &:hover,
  &:focus {
    color: ${(props) => props.theme.colors.darkRed};
  }
`;
