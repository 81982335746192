import "react-datepicker/dist/react-datepicker.css";
import React, { useState, useContext } from "react";
import CustomTitle from "../../reusable-components/custom-title/CustomTitle";
import CustomButton from "../../reusable-components/custom-button/CustomButton";
import { StyledForm, StyledButtonWr } from "./tableForms.styles";
import { ReactComponent as ArrowLeftIcon } from "../../../assets/icons/arrow-left.svg";
import { deleteReservationDocument } from "../../../firebase/firebase.utils";
import { ReservationsContext } from "../../../providers/reservations/Reservations.provider";
import { formatDate, formatTime } from "../../../utils/utils";

const RemoveReservationModalContent = () => {
  const { selectReservation, selectedReservation, changeModalSection } =
    useContext(ReservationsContext);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { customerName } = selectedReservation;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      await deleteReservationDocument(selectedReservation);
      setIsSubmitting(false);
      changeModalSection("reservations");
      selectReservation(null);
    } catch (error) {
      console.log(error);
      setIsSubmitting(false);
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    changeModalSection("reservations");
    selectReservation(null);
  };

  return (
    <StyledForm onSubmit={handleSubmit}>
      <CustomButton customStyle="icon" onClick={handleClick}>
        <ArrowLeftIcon />
      </CustomButton>
      <CustomTitle center>Delete reservation</CustomTitle>
      <p style={{ textAlign: "center" }}>
        Are you sure to delete reservations of customer {customerName} at{" "}
        {formatDate(selectedReservation.reservationDateTime.seconds)}{" "}
        {formatTime(selectedReservation.reservationDateTime.seconds)}?
      </p>
      <StyledButtonWr>
        <CustomButton isLoading={isSubmitting}>Delete</CustomButton>
        <CustomButton
          type="button"
          customStyle="secondary"
          onClick={handleClick}>
          Cancel
        </CustomButton>
      </StyledButtonWr>
    </StyledForm>
  );
};

export default RemoveReservationModalContent;
