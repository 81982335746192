import React from "react";

import { StyledImage } from "./logo.styles";

import { Link } from "react-router-dom";

const Logo = ({ linkToHome = false }) => {
  return (
    <>
      {linkToHome ? (
        <Link to="/">
          <StyledImage
            src="/images/logo.png"
            alt="R Booking"
            width="400"
            height="92"
          />
        </Link>
      ) : (
        <StyledImage
          src="/images/logo.png"
          alt="R Booking"
          width="400"
          height="92"
        />
      )}
    </>
  );
};

export default Logo;
