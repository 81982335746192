import "react-datepicker/dist/react-datepicker.css";
import React, { useState, useContext } from "react";
import CustomTitle from "../../reusable-components/custom-title/CustomTitle";
import CustomButton from "../../reusable-components/custom-button/CustomButton";
import { StyledForm, StyledButtonWr, DateInputWr } from "./tableForms.styles";
import FormInput from "../../reusable-components/form-input/FormInput";
import { ReactComponent as ArrowLeftIcon } from "../../../assets/icons/arrow-left.svg";
import ReactDatePicker from "react-datepicker";
import {
  addReservationDocument,
  isTimeslotAvailable,
} from "../../../firebase/firebase.utils";
import { ReservationsContext } from "../../../providers/reservations/Reservations.provider";
import Message from "../../reusable-components/message/Message";
import { useAuth } from "../../../hooks/useAuth";

const defaultFormData = {
  customerName: "",
  customerPhone: "",
  customerEmail: "",
};

const AddReservationModalContent = ({ table: { id, tableNumber } }) => {
  const { changeModalSection } = useContext(ReservationsContext);
  const customAuth = useAuth();

  const [startDate, setStartDate] = useState("");
  const [addError, setAddError] = useState(null);
  const [formData, setFormData] = useState(defaultFormData);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setAddError(null);

    try {
      const timeslotAvailable = await isTimeslotAvailable(
        customAuth.user.uid,
        id,
        startDate
      );
      if (!timeslotAvailable) {
        setAddError("Table is already reserved during selected date and time");
        setIsSubmitting(false);
        return;
      }

      await addReservationDocument(
        {
          ...formData,
          reservationDateTime: startDate,
        },
        id,
        customAuth.user.uid
      );
      setIsSubmitting(false);
      changeModalSection("reservations");
    } catch (error) {
      console.log(error);
      setIsSubmitting(false);
    }
  };

  const handleChange = (event) => {
    const { value, name } = event.target;

    setFormData({ ...formData, [name]: value });
  };

  const handleClick = (e) => {
    e.preventDefault();
    changeModalSection("reservations");
  };

  return (
    <StyledForm onSubmit={handleSubmit}>
      <CustomButton customStyle="icon" onClick={handleClick}>
        <ArrowLeftIcon />
      </CustomButton>
      <CustomTitle center>Add reservation on table #{tableNumber}</CustomTitle>
      <DateInputWr>
        <label htmlFor="startDate">Date and time </label>
        <ReactDatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          showTimeSelect
          timeFormat="hh:mm aa"
          timeIntervals={60}
          timeCaption="Time"
          dateFormat="MM/dd/yyyy h:mm aa"
          name="startDate"
          id="startDate"
          required
        />
      </DateInputWr>
      <FormInput
        type="text"
        label="Name"
        id="customerName"
        value={formData.customerName}
        onChange={handleChange}
        required
      />
      <FormInput
        type="text"
        label="Phone"
        id="customerPhone"
        value={formData.customerPhone}
        onChange={handleChange}
        required
      />
      <FormInput
        type="email"
        label="Email"
        id="customerEmail"
        value={formData.customerEmail}
        onChange={handleChange}
      />
      {addError && <Message type="danger">{addError}</Message>}
      <StyledButtonWr>
        <CustomButton isLoading={isSubmitting}>Add</CustomButton>
        <CustomButton
          type="button"
          customStyle="secondary"
          onClick={handleClick}>
          Cancel
        </CustomButton>
      </StyledButtonWr>
    </StyledForm>
  );
};

export default AddReservationModalContent;
