import React from "react";
import Spinner from "../spinner/Spinner";

import { StyledButton, StyledLink } from "./CustomButton.styles";

const CustomButton = ({
  link,
  to,
  isLoading,
  handleClick,
  customStyle = "",
  className,
  children,
  ...otherProps
}) => {
  return link ? (
    <StyledLink
      to={to}
      $customStyle={customStyle}
      className={className}
      {...otherProps}>
      {isLoading ? <Spinner /> : children}
    </StyledLink>
  ) : (
    <StyledButton
      $customStyle={customStyle}
      onClick={handleClick}
      className={className}
      {...otherProps}
      disabled={isLoading ? true : false}>
      {isLoading ? (
        <Spinner theme={customStyle === "secondary" && "dark"} />
      ) : (
        children
      )}
    </StyledButton>
  );
};

export default CustomButton;
