import React, { useState, useEffect, useContext } from "react";
import CustomButton from "../../reusable-components/custom-button/CustomButton";
import CustomTitle from "../../reusable-components/custom-title/CustomTitle";
import ReservationsTable from "../../reusable-components/reservations-table/ReservationsTable";
import Spinner from "../../reusable-components/spinner/Spinner";
import { getReservationsByTable } from "../../../firebase/firebase.utils";
import { ReservationsContext } from "../../../providers/reservations/Reservations.provider";
import { StyledSpinnerWr } from "./tableForms.styles";
import { useAuth } from "../../../hooks/useAuth";

const TableReservations = ({ table: { tableNumber, id } }) => {
  const customAuth = useAuth();

  const { changeModalSection } = useContext(ReservationsContext);

  const [selectedReservation, setSelectedReservation] = useState("upcoming");
  const [loadingReservations, setLoadingReservations] = useState(true);
  const [reservations, setReservations] = useState([]);

  const handleChangeValue = (e) => {
    setSelectedReservation(e.target.value);
  };

  useEffect(() => {
    if (!customAuth.user?.uid) return;

    const fetchReservations = async () => {
      try {
        const reservations = await getReservationsByTable(
          customAuth.user?.uid,
          id,
          selectedReservation
        );

        setReservations(reservations);
        setLoadingReservations(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchReservations();
  }, [id, selectedReservation, customAuth.user?.uid]);

  const renderReservations = () => {
    return reservations.length > 0 ? (
      <ReservationsTable reservations={reservations} />
    ) : (
      <CustomTitle>No reservations found</CustomTitle>
    );
  };

  return (
    <>
      <CustomTitle center>Reservations for table #{tableNumber}</CustomTitle>
      <fieldset>
        <legend>Reservations</legend>
        <label>
          <input
            type="radio"
            name="upcoming"
            id="upcoming"
            value="upcoming"
            onChange={handleChangeValue}
            checked={selectedReservation === "upcoming" && "checked"}
          />
          Upcoming
        </label>
        <label>
          <input
            type="radio"
            name="past"
            value="past"
            id="past"
            onChange={handleChangeValue}
            checked={selectedReservation === "past" && "checked"}
          />
          Past
        </label>
      </fieldset>
      {loadingReservations ? (
        <StyledSpinnerWr>
          <Spinner theme="dark" />
        </StyledSpinnerWr>
      ) : (
        renderReservations()
      )}
      <CustomButton onClick={() => changeModalSection("addReservation")}>
        Add New
      </CustomButton>
    </>
  );
};

export default TableReservations;
