import styled from "styled-components";

export const StyledCardBody = styled.div`
  p {
    text-align: center;
    margin-bottom: 2em;
  }
  form > div {
    margin-bottom: 1em;
    label {
      display: inline-block;
      width: 35%;
      text-align: right;
    }
  }
  button {
    text-align: center;
    margin: 0 0 0 calc(35% + 0.7em);
  }
`;
