import React, { useState } from "react";

import FormInput from "../form-input/FormInput";
import CustomButton from "../custom-button/CustomButton";
import CustomLink from "../custom-link/CustomLink";
import Message from "../message/Message";

import { StyledDiv, StyledButtonWr } from "./SignUp.styles";
import { useAuth } from "../../../hooks/useAuth";
import { authMessages } from "../../../firebase/authMessages";
import { useHistory } from "react-router";

const SignUp = () => {
  const defaultFormData = {
    displayName: "",
    email: "",
    password: "",
    confirmPassword: "",
    isLoading: "",
    error: "",
  };

  const [formData, setFormData] = useState(defaultFormData);
  const customAuth = useAuth();
  const history = useHistory();

  const { displayName, email, password, confirmPassword, isLoading, error } =
    formData;

  const handleChange = (event) => {
    const { value, name } = event.target;

    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormData({ ...formData, isLoading: true });

    if (password !== confirmPassword) {
      setFormData({
        ...formData,
        error: authMessages["password-no-match"],
        isLoading: false,
      });
      return;
    }

    try {
      await customAuth.signUp(email, password, displayName);
      history.push("/");
    } catch (error) {
      console.log(error);
      const customMessage = authMessages[error.code];

      setFormData({
        ...formData,
        error: customMessage ? customMessage : error.code,
      });
    }
  };

  return (
    <StyledDiv>
      <p>
        <strong>Sign up to access Restaurant Booking</strong>
      </p>
      <form onSubmit={handleSubmit}>
        <FormInput
          onChange={handleChange}
          label="Name"
          name="displayName"
          type="displayName"
          id="displayName"
          value={formData.displayName}
          required
        />
        <FormInput
          onChange={handleChange}
          label="Email Address"
          name="email"
          type="email"
          id="email"
          value={formData.email}
          required
        />
        <FormInput
          onChange={handleChange}
          label="Password"
          name="password"
          type="password"
          id="password"
          value={formData.password}
          required
        />
        <FormInput
          onChange={handleChange}
          label="Confirm Password"
          name="confirmPassword"
          type="Password"
          id="confirmPassword"
          value={formData.confirmPassword}
          required
        />
        {error && <Message type="error">{error}</Message>}
        <StyledButtonWr>
          <CustomButton type="submit" isLoading={isLoading}>
            Sign Up
          </CustomButton>
        </StyledButtonWr>
        <p>
          Already a member? <CustomLink to="/login">Log in</CustomLink> here.
        </p>
      </form>
    </StyledDiv>
  );
};

export default SignUp;
