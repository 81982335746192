import React, { useState, createContext, useEffect } from "react";
import { getTables } from "../../firebase/firebase.utils";
import { useAuth } from "../../hooks/useAuth";
import {
  moveTableInTables,
  addTableInTables,
  editTableInTables,
  deleteTableInTables,
} from "./tables.utils";

export const TablesContext = createContext({
  tables: [],
  setTables: () => {},
  moveTable: () => {},
  editTable: () => {},
  deleteTable: () => {},
});

const TablesProvider = ({ children }) => {
  const [tables, setTables] = useState([]);
  const customAuth = useAuth();

  const moveTable = (table, newPosition) =>
    setTables(moveTableInTables(tables, table, newPosition));
  const addTable = (table) => setTables(addTableInTables(tables, table));
  const editTable = (table) => setTables(editTableInTables(tables, table));
  const deleteTable = (table) => setTables(deleteTableInTables(tables, table));

  useEffect(() => {
    if (!customAuth.user?.uid) return;

    const fetchTables = async () => {
      try {
        const tables = await getTables(customAuth.user.uid);
        setTables(tables);
      } catch (error) {
        console.log(error);
      }
    };

    fetchTables();
  }, [customAuth]);

  return (
    <TablesContext.Provider
      value={{ tables, moveTable, addTable, editTable, deleteTable }}>
      {children}
    </TablesContext.Provider>
  );
};

export default TablesProvider;
