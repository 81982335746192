import React, { useRef } from "react";
import { createPortal } from "react-dom";
import {
  StyledModalOverlay,
  StyledModal,
  StyledModalBody,
} from "./modal.styles";
import useOnClickOutside from "../../../hooks/useOutsideClick";
import CustomButton from "../custom-button/CustomButton";
import { ReactComponent as CloseIcon } from "../../../assets/icons/times.svg";

const Modal = ({ show, onClose, width = false, children }) => {
  const modalWrapperRef = useRef(null);

  useOnClickOutside(modalWrapperRef, () => {
    onClose();
  });

  const handleCloseClick = (e) => {
    e.preventDefault();
    onClose();
  };

  const modalContent = show ? (
    <StyledModalOverlay>
      <StyledModal ref={modalWrapperRef} $width={width}>
        <CustomButton customStyle="icon" onClick={handleCloseClick}>
          <CloseIcon />
        </CustomButton>
        <StyledModalBody>{children}</StyledModalBody>
      </StyledModal>
    </StyledModalOverlay>
  ) : null;

  return createPortal(modalContent, document.getElementById("modal-root"));
};

export default Modal;
