import styled from "styled-components";

export const StyledDiv = styled.div`
  width: 100%;
  max-width: 300px;
  margin: 1em;
  padding: 1em;
  border: 1px solid ${(props) => props.theme.colors.grey};
`;

export const StyledCardHeader = styled.div`
  background: ${(props) => props.theme.colors.grey};
  margin: -1em -1em 0 -1em;
  padding: 1em;
  font-weight: 800;
`;

export const StyledCardBody = styled.div`
  padding: 1em 0 0;
`;

export const StyledCardItem = styled.div`
  margin: 0 0 1.5em;
  &:last-child {
    margin: 0;
  }
  p {
    display: flex;
    margin: 0 0 0.2em 0;
    justify-content: space-between;
  }
`;
