import React from "react";
import Card from "../../reusable-components/card/Card";
import LoginWrapper from "../../reusable-components/login-wrapper/LoginWrapper";
import SignUp from "../../reusable-components/sign-up/SignUp";

const SignUpPage = () => {
  return (
    <LoginWrapper>
      <Card title="Sign Up">
        <SignUp />
      </Card>
    </LoginWrapper>
  );
};

export default SignUpPage;
