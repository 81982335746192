import React from "react";
import Spinner from "../../reusable-components/spinner/Spinner";
import { useAuth } from "../../../hooks/useAuth";
import { Route, Redirect } from "react-router-dom";

const PublicRoute = ({ children, ...rest }) => {
  const customAuth = useAuth();

  if (customAuth.loadingAuthState) {
    return <Spinner theme="dark" pageHeight size={50} />; // @todo add spinner
  }

  return (
    <Route
      {...rest}
      render={() =>
        customAuth.user ? (
          <Redirect
            to={{
              pathname: "/",
            }}
          />
        ) : (
          children
        )
      }
    />
  );
};

export default PublicRoute;
