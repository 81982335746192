import React from "react";
import { StyledDragOverlay } from "./tableDragOverlay.styles";

const TableDragOverlay = ({ draggingTableNumber }) => {
  return (
    <StyledDragOverlay
      dropAnimation={{
        duration: 250,
        easing: "cubic-bezier(0.18, 0.67, 0.6, 1.22)",
      }}>
      {draggingTableNumber && `Table #${draggingTableNumber}`}
    </StyledDragOverlay>
  );
};

export default TableDragOverlay;
