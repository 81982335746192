import styled from "styled-components";

import { Link } from "react-router-dom";

export const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colors.darkGrey};
  font-weight: 600;
  transition: color 250ms ease-in-out;

  &:focus,
  &:hover {
    color: ${(props) => props.theme.colors.red};
  }
`;
