import React from "react";
import { useAuth } from "../../../hooks/useAuth";

import Logo from "../../reusable-components/logo/Logo";
import MyAccountNav from "../my-account-nav/MyAccountNav";
import Navigation from "../navigation/Navigation";

import { StyledHeader, StyledContainer } from "./Header.styles";

const Header = () => {
  const customAuth = useAuth();

  if (!!customAuth.user && !customAuth.loadingAuthState) {
    return (
      <StyledHeader>
        <StyledContainer>
          <Logo linkToHome />
          <Navigation />
          <MyAccountNav />
        </StyledContainer>
      </StyledHeader>
    );
  }

  return null;
};

export default Header;
