import styled, { css } from "styled-components";

export const StyledP = styled.p`
  padding: 1em 1em;
  ${({ $type }) =>
    $type === "error" &&
    css`
      color: #721c24;
      background-color: #f8d7da;
    `};
  ${({ $type }) =>
    $type === "danger" &&
    css`
      color: #856404;
      background-color: #fff3cd;
    `};
  ${({ $type }) =>
    $type === "success" &&
    css`
      color: #155724;
      background-color: #d4edda;
    `};
`;
