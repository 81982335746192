import React, { useContext } from "react";
import CustomButton from "../custom-button/CustomButton";
import { formatDate, formatTime } from "../../../utils/utils";
import { StyledTable } from "./reservationsTable.styles";
import { ReactComponent as TrashIcon } from "../../../assets/icons/trash.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit.svg";
import { ReservationsContext } from "../../../providers/reservations/Reservations.provider";

const ReservationsTable = ({ reservations }) => {
  const { selectReservation, changeModalSection } =
    useContext(ReservationsContext);

  const handleClick = (reservation) => {
    selectReservation(reservation);
    changeModalSection("editReservation");
  };

  const handleClickDelete = (reservation) => {
    selectReservation(reservation);
    changeModalSection("deleteReservation");
  };

  return (
    <StyledTable>
      <thead>
        <tr>
          <th>Date</th>
          <th>Time</th>
          <th>Name</th>
          <th>Phone</th>
          <th>Email</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {reservations.map((reservation) => (
          <tr key={reservation.id}>
            <td>{formatDate(reservation.reservationDateTime.seconds)}</td>
            <td>{formatTime(reservation.reservationDateTime.seconds)}</td>
            <td>{reservation.customerName}</td>
            <td>{reservation.customerPhone}</td>
            <td>{reservation.customerEmail}</td>
            <td>
              <CustomButton
                customStyle="icon"
                type="button"
                onClick={() => handleClick(reservation)}>
                <EditIcon />
              </CustomButton>
              <CustomButton
                customStyle="icon"
                type="button"
                onClick={() => handleClickDelete(reservation)}>
                <TrashIcon />
              </CustomButton>
            </td>
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
};

export default ReservationsTable;
