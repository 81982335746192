import styled from "styled-components";

export const CardsWr = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const DateInputWr = styled.div`
  label {
    display: inline-block;
    margin-bottom: 1em;
  }
  input {
    padding: 1em;
    font-size: 1rem;
  }
`;
