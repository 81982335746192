import React, { useState } from "react";
import FormInput from "../../reusable-components/form-input/FormInput";
import CustomTitle from "../../reusable-components/custom-title/CustomTitle";
import CustomButton from "../../reusable-components/custom-button/CustomButton";
import { StyledForm, StyledButtonWr } from "./tableForms.styles";

const AddTableForm = ({
  getNextTableNumber,
  tablePosition,
  handleAddTableSubmit,
}) => {
  const [formData, setFormData] = useState({
    tableNumber: getNextTableNumber(),
    seats: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { tableNumber, seats } = formData;

  const handleChange = (event) => {
    const { value, name } = event.target;

    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    await handleAddTableSubmit({
      tableNumber: tableNumber,
      seats: seats,
      tablePosition: tablePosition,
    });
  };

  return (
    <StyledForm onSubmit={handleSubmit}>
      <CustomTitle center>Add table</CustomTitle>
      <FormInput
        type="number"
        label="Table number"
        id="tableNumber"
        onChange={handleChange}
        value={tableNumber}
        required
      />
      <FormInput
        type="number"
        label="Count of seats"
        id="seats"
        onChange={handleChange}
        value={seats}
        required
      />
      <StyledButtonWr>
        <CustomButton isLoading={isSubmitting}>Submit</CustomButton>
      </StyledButtonWr>
    </StyledForm>
  );
};

export default AddTableForm;
