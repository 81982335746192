import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { ThemeProvider } from "styled-components";

import LoginPage from "./components/route-components/login/LoginPage";
import Header from "./components/main/header/Header";
import SignUpPage from "./components/route-components/sign-up/SignUpPage";
import { AuthProvider } from "./hooks/useAuth";
import Dashboard from "./components/route-components/dashboard/Dashboard";
import PrivateRoute from "./components/route-components/private-route/PrivateRoute";
import PublicRoute from "./components/route-components/public-route/PublicRoute";
import EditLayout from "./components/route-components/edit-layout/EditLayout";
import TablesProvider from "./providers/tables/tables.provider";
import Reservations from "./components/route-components/reservations/Reservations";
import ReservationsProvider from "./providers/reservations/Reservations.provider";
import ErrorBoundary from "./components/main/error-boundary/ErrorBoundary";

const theme = {
  colors: {
    red: "#DA4D46",
    darkRed: "#ac1d15",
    darkGrey: "#3E444D",
    grey: "rgba(0, 0, 0, 0.125)",
    lightGrey: "rgba(0, 0, 0, 3%)",
  },
  breakpoints: {
    xl: 1270,
    lg: 992,
    md: 768,
    sm: 576,
    xs: 350,
  },
};

const App = () => {
  return (
    <AuthProvider>
      <TablesProvider>
        <ThemeProvider theme={theme}>
          <ErrorBoundary>
            <Router>
              <Header />
              <Switch>
                <PublicRoute path="/login">
                  <LoginPage />
                </PublicRoute>
                <PublicRoute path="/sign-up">
                  <SignUpPage />
                </PublicRoute>
                {/* @todo add reset password */}
                <PrivateRoute exact path="/">
                  <Dashboard />
                </PrivateRoute>
                <PrivateRoute path="/reservations">
                  <ReservationsProvider>
                    <Reservations />
                  </ReservationsProvider>
                </PrivateRoute>
                <PrivateRoute path="/edit-layout">
                  <EditLayout />
                </PrivateRoute>
                <Route path="*">404</Route>
              </Switch>
            </Router>
          </ErrorBoundary>
        </ThemeProvider>
      </TablesProvider>
    </AuthProvider>
  );
};

export default App;
