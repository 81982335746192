import React from "react";
import { formatDate, formatTime } from "../../../utils/utils";
import {
  StyledDiv,
  StyledCardHeader,
  StyledCardBody,
  StyledCardItem,
} from "./reservationCard.styles";

const ReservationCard = ({ tableNumber, assignedReservations }) => {
  return (
    <StyledDiv>
      <StyledCardHeader>Table #{tableNumber}</StyledCardHeader>
      <StyledCardBody>
        {assignedReservations.length > 0
          ? assignedReservations.map((reservation) => {
              return (
                <StyledCardItem key={reservation.id}>
                  <p>
                    <strong>
                      {formatTime(reservation.reservationDateTime.seconds)}
                    </strong>
                    <span>
                      {formatDate(reservation.reservationDateTime.seconds)}
                    </span>
                  </p>

                  <p>{reservation.customerName}</p>
                  <p>{reservation.customerPhone}</p>
                  <p>{reservation.customerEmail}</p>
                </StyledCardItem>
              );
            })
          : "No reservations"}
      </StyledCardBody>
    </StyledDiv>
  );
};

export default ReservationCard;
