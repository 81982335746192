import React, { useState } from "react";
import Card from "../../reusable-components/card/Card";
import FormInput from "../../reusable-components/form-input/FormInput";
import CustomButton from "../../reusable-components/custom-button/CustomButton";
import { StyledContainer } from "./RestaurantName.styles";
import CardBody from "../../reusable-components/card-body/CardBody";
import { useAuth } from "../../../hooks/useAuth";

const RestaurantName = () => {
  const [name, setName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const customAuth = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    try {
      await customAuth.addRestaurantName(name);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    setName(e.target.value);
  };

  return (
    <StyledContainer>
      <Card title="Please fill in the name of your restaurant">
        <CardBody>
          <form onSubmit={handleSubmit}>
            <FormInput
              onChange={handleChange}
              value={name}
              id="name"
              label="Restaurant name"
              required
            />
            <CustomButton isLoading={isSubmitting}>Submit</CustomButton>
          </form>
        </CardBody>
      </Card>
    </StyledContainer>
  );
};

export default RestaurantName;
