import React from "react";
import RestaurantName from "../restaurant-name/RestaurantName";
import Spinner from "../../reusable-components/spinner/Spinner";
import { useAuth } from "../../../hooks/useAuth";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ children, ...rest }) => {
  const customAuth = useAuth();

  if (customAuth.loadingAuthState) {
    return <Spinner theme="dark" pageHeight size={50} />; // @todo add spinner
  }

  const restaurants = customAuth?.user?.restaurants || [];

  return (
    <Route
      {...rest}
      render={() =>
        customAuth?.user && !customAuth.loadingAuthState ? (
          restaurants.length === 0 ? (
            <RestaurantName />
          ) : (
            children
          )
        ) : (
          <Redirect
            to={{
              pathname: "/login",
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
