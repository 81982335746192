import React, { useState, createContext } from "react";

export const ReservationsContext = createContext({
  modalSection: "reservations",
  setModalSection: "reservations",
  selectedReservation: null,
  changeModalSection: () => {},
});

const ReservationsProvider = ({ children }) => {
  const [modalSection, setModalSection] = useState("reservations");
  const [selectedReservation, setSelectedReservation] = useState(null);

  const changeModalSection = (sectionName) => {
    setModalSection(sectionName);
  };

  const selectReservation = (reservation) => {
    setSelectedReservation({ ...reservation });
  };

  return (
    <ReservationsContext.Provider
      value={{
        modalSection,
        changeModalSection,
        selectedReservation,
        selectReservation,
      }}>
      {children}
    </ReservationsContext.Provider>
  );
};

export default ReservationsProvider;
