import React from "react";
import { StyledSpinnerWr } from "./Spinner.styles";

const Spinner = ({ theme = "light", pageHeight = false, size = "25" }) => {
  return (
    <StyledSpinnerWr $theme={theme} $pageHeight={pageHeight} $size={size}>
      <div></div>
    </StyledSpinnerWr>
  );
};

export default Spinner;
