import React from "react";

import { StyledHeading } from "./CustomTitle.styles";

const CustomTitle = ({ heading = "h1", center = false, children }) => {
  return (
    <StyledHeading as={heading} $center={center}>
      {children}
    </StyledHeading>
  );
};

export default CustomTitle;
