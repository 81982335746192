import React from "react";
import Logo from "../logo/Logo";

import { StyledDiv } from "./LoginWrapper.styles";

const LoginWrapper = ({ children }) => {
  return (
    <StyledDiv>
      <Logo />
      {children}
    </StyledDiv>
  );
};

export default LoginWrapper;
