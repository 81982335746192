import React, { useState, useContext } from "react";
import { DndContext } from "@dnd-kit/core";
import CustomTitle from "../../reusable-components/custom-title/CustomTitle";
import DisplayGrid from "../../reusable-components/display-grid/DisplayGrid";
import Droppable from "../../reusable-components/droppable/Droppable";
import DraggableTable from "../draggable-table/DraggableTable";
import TableDragOverlay from "../../reusable-components/table-drag-overlay/TableDragOverlay";
import Modal from "../../reusable-components/modal/Modal";
import { TablesContext } from "../../../providers/tables/tables.provider";
import { hasArrayObjectAttributeValue } from "../../../utils/utils";
import {
  addTableDocument,
  updateTableDoc,
} from "../../../firebase/firebase.utils";
import { useAuth } from "../../../hooks/useAuth";
import AddTableForm from "../../main/forms/AddTableForm";
import LayoutWr from "../../reusable-components/layout-wr/LayoutWr";

const EditLayout = () => {
  const customAuth = useAuth();

  const { tables, moveTable, addTable } = useContext(TablesContext);
  const [draggingTableNumber, setDraggingTableNumber] = useState(null);
  const [showAddTableModal, setShowAddTableModal] = useState(false);
  const [tablePosition, setTablePosition] = useState(null);

  const handleDragStart = (event) => {
    setDraggingTableNumber(event.active.data.current.tableNumber);
  };

  const handleDragEnd = async (event) => {
    const { active, over } = event;
    const { position } = over.data.current;
    const { tableNumber } = active.data.current;

    if (hasArrayObjectAttributeValue(tables, "tablePosition", position)) return;

    const draggedTable = tables.find(
      (table) => table.tableNumber === tableNumber
    );

    try {
      moveTable(draggedTable, position);
      setDraggingTableNumber(null);

      await updateTableDoc(customAuth.user.uid, {
        ...draggedTable,
        tablePosition: position,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = (id) => {
    if (hasArrayObjectAttributeValue(tables, "tablePosition", id)) return;
    setTablePosition(id);
    setShowAddTableModal({ ...showAddTableModal, addForm: true });
  };

  const handleAddTableSubmit = async (table) => {
    try {
      const tableId = await addTableDocument(customAuth.user, table);
      addTable({ ...table, id: tableId, user: customAuth.user.uid });
    } catch (error) {
      console.log(error);
    }
    setShowAddTableModal(false);
  };

  const getNextTableNumber = () => {
    let highestNumber = 0;

    tables.map((table) => {
      if (table.tableNumber > highestNumber) {
        highestNumber = +table.tableNumber;
      }
      return highestNumber;
    });

    return +highestNumber + 1;
  };

  return (
    <LayoutWr>
      <CustomTitle>Edit layout</CustomTitle>
      <DndContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
        <TableDragOverlay draggingTableNumber={draggingTableNumber} />
        <DisplayGrid rows={15} cols={10}>
          {Array(150)
            .fill(null)
            .map((_, index) => (
              <Droppable
                key={index}
                id={index}
                disabled={hasArrayObjectAttributeValue(
                  tables,
                  "tablePosition",
                  index
                )}
                onClick={() => handleClick(index)}>
                {tables.map(
                  (table) =>
                    table.tablePosition === index && (
                      <DraggableTable
                        key={table.tableNumber}
                        table={{ ...table }}
                        isDragging={
                          draggingTableNumber === table.tableNumber
                            ? true
                            : false
                        }
                      />
                    )
                )}
              </Droppable>
            ))}
        </DisplayGrid>
        <Modal
          onClose={() => setShowAddTableModal(false)}
          show={showAddTableModal}
          width="500px">
          <AddTableForm
            getNextTableNumber={getNextTableNumber}
            handleAddTableSubmit={handleAddTableSubmit}
            tablePosition={tablePosition}
          />
        </Modal>
      </DndContext>
    </LayoutWr>
  );
};

export default EditLayout;
