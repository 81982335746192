import React, { useState } from "react";
import Draggable from "../../reusable-components/draggable/Draggable";
import CustomButton from "../../reusable-components/custom-button/CustomButton";
import Modal from "../../reusable-components/modal/Modal";
import { ReactComponent as Seat } from "../../../assets/icons/seat.svg";
import { ReactComponent as Edit } from "../../../assets/icons/edit.svg";
import { StyledDraggableTable } from "./draggableTable.styles";
import EditTableForm from "../../main/forms/EditTableForm";

const DraggableTable = ({ table, isStatic = false }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const { tableNumber, seats, isDragging } = table;

  return (
    <StyledDraggableTable>
      <Draggable id={tableNumber} isDragging={isDragging} isStatic={isStatic}>
        <div>
          {`Table #${tableNumber}`}
          <span>
            <Seat />
            {seats}
          </span>
        </div>
      </Draggable>

      {!isStatic && (
        <>
          <CustomButton
            customStyle="icon"
            handleClick={() => setShowEditModal(true)}>
            <Edit />
          </CustomButton>
          <Modal
            onClose={() => setShowEditModal(false)}
            show={showEditModal}
            width="500px">
            <EditTableForm
              table={{ ...table }}
              setShowEditModal={setShowEditModal}
            />
          </Modal>
        </>
      )}
    </StyledDraggableTable>
  );
};

export default DraggableTable;
