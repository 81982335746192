import React, { useState, useEffect, useContext } from "react";
import ReactDatePicker from "react-datepicker";
import Container from "../../reusable-components/container/Container";
import CustomTitle from "../../reusable-components/custom-title/CustomTitle";
import { getReservationsByDate } from "../../../firebase/firebase.utils";
import { useAuth } from "../../../hooks/useAuth";
import { CardsWr, DateInputWr } from "./dashboard.styles";
import { TablesContext } from "../../../providers/tables/tables.provider";
import Spinner from "../../reusable-components/spinner/Spinner";
import ReservationCard from "../../reusable-components/reservation-card/ReservationCard";

const Dashboard = () => {
  const customAuth = useAuth();
  const { displayName } = customAuth.user;
  const { tables } = useContext(TablesContext);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [reservations, setReservations] = useState(null);
  const [isLoadingReservations, setIsLoadingReservations] = useState(true);

  useEffect(() => {
    setIsLoadingReservations(true);
    const fetchReservations = async () => {
      try {
        const reservations = await getReservationsByDate(
          customAuth.user.uid,
          selectedDate
        );
        setReservations(reservations);
      } catch (error) {
        console.log(error);
      }
      setIsLoadingReservations(false);
    };

    fetchReservations();
  }, [customAuth.user.uid, selectedDate]);

  const renderTableReservations = () => {
    return tables.map((table) => {
      const assignedReservations = reservations.filter(
        (reservation) => reservation.tableId === table.id
      );

      return (
        <ReservationCard
          key={table.id}
          tableNumber={table.tableNumber}
          assignedReservations={assignedReservations}
        />
      );
    });
  };

  return (
    <Container>
      <CustomTitle>
        Hello {displayName}, here you can review the reservations for the
        selected date.
      </CustomTitle>
      <DateInputWr>
        <label htmlFor="selectedDate">Select date:</label>
        <ReactDatePicker
          selected={selectedDate}
          onChange={(date) => setSelectedDate(date)}
          dateFormat="MM/dd/yyyy"
          name="selectedDate"
          id="selectedDate"
          required
        />
      </DateInputWr>
      {isLoadingReservations ? (
        <Spinner theme="dark" size={40} />
      ) : (
        <CardsWr>{renderTableReservations()}</CardsWr>
      )}
    </Container>
  );
};

export default Dashboard;
