import "react-datepicker/dist/react-datepicker.css";
import React, { useState, useContext } from "react";
import CustomTitle from "../../reusable-components/custom-title/CustomTitle";
import CustomButton from "../../reusable-components/custom-button/CustomButton";
import { StyledForm, StyledButtonWr, DateInputWr } from "./tableForms.styles";
import FormInput from "../../reusable-components/form-input/FormInput";
import { ReactComponent as ArrowLeftIcon } from "../../../assets/icons/arrow-left.svg";
import ReactDatePicker from "react-datepicker";
import {
  isTimeslotAvailable,
  updateReservationDocument,
} from "../../../firebase/firebase.utils";
import { ReservationsContext } from "../../../providers/reservations/Reservations.provider";
import Message from "../../reusable-components/message/Message";
import { useAuth } from "../../../hooks/useAuth";

const EditReservationModalContent = () => {
  const { selectReservation, selectedReservation, changeModalSection } =
    useContext(ReservationsContext);
  const customAuth = useAuth();

  const defaultDate = new Date(
    selectedReservation?.reservationDateTime?.seconds * 1000 || ""
  );

  const [startDate, setStartDate] = useState(defaultDate);
  const [formData, setFormData] = useState({ ...selectedReservation });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [updateError, setUpdateError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setUpdateError(null);

    try {
      if (defaultDate.toISOString() !== startDate.toISOString()) {
        const timeslotAvailable = await isTimeslotAvailable(
          customAuth.user.uid,
          selectedReservation.tableId,
          startDate
        );

        if (!timeslotAvailable) {
          setUpdateError(
            "Table is already reserved during selected date and time"
          );
          setIsSubmitting(false);
          return;
        }
      }

      await updateReservationDocument({
        ...selectedReservation,
        ...formData,
        reservationDateTime: startDate,
      });

      setIsSubmitting(false);
      selectReservation(null);
      changeModalSection("reservations");
    } catch (error) {
      console.log(error);
      setIsSubmitting(false);
    }
  };

  const handleChange = (event) => {
    const { value, name } = event.target;

    setFormData({ ...formData, [name]: value });
  };

  const handleClick = (e) => {
    e.preventDefault();
    changeModalSection("reservations");
    selectReservation(null);
  };

  return (
    <StyledForm onSubmit={handleSubmit}>
      <CustomButton customStyle="icon" onClick={handleClick}>
        <ArrowLeftIcon />
      </CustomButton>
      <CustomTitle center>Edit reservation</CustomTitle>
      <DateInputWr>
        <label htmlFor="startDate">Date and time </label>
        <ReactDatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          showTimeSelect
          timeFormat="hh:mm aa"
          timeIntervals={60}
          timeCaption="Time"
          dateFormat="MM/dd/yyyy h:mm aa"
          name="startDate"
          id="startDate"
        />
      </DateInputWr>
      <FormInput
        type="text"
        label="Name"
        id="customerName"
        value={formData.customerName}
        onChange={handleChange}
        required
      />
      <FormInput
        type="text"
        label="Phone"
        id="customerPhone"
        value={formData.customerPhone}
        onChange={handleChange}
        required
      />
      <FormInput
        type="email"
        label="Email"
        id="customerEmail"
        value={formData.customerEmail}
        onChange={handleChange}
      />
      {updateError && <Message type="danger">{updateError}</Message>}
      <StyledButtonWr>
        <CustomButton isLoading={isSubmitting}>Confirm</CustomButton>
        <CustomButton
          type="button"
          customStyle="secondary"
          onClick={handleClick}>
          Cancel
        </CustomButton>
      </StyledButtonWr>
    </StyledForm>
  );
};

export default EditReservationModalContent;
