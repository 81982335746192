import React from "react";

import BaseLink from "../../reusable-components/base-link/BaseLink";

import { StyledNavigation } from "./Navigation.styles";

const MENU_LINKS = [
  {
    title: "Dashboard",
    url: "/",
  },
  {
    title: "Reservations",
    url: "/reservations",
  },
  {
    title: "Edit layout",
    url: "/edit-layout",
  },
];

const Navigation = () => {
  return (
    <StyledNavigation>
      <ul>
        {MENU_LINKS.map((link) => (
          <li key={link.url}>
            <BaseLink to={link.url}>{link.title}</BaseLink>
          </li>
        ))}
      </ul>
    </StyledNavigation>
  );
};

export default Navigation;
