export const moveTableInTables = (tables, tableToAdd, newPosition) => {
  return tables.map((table) => {
    if (tableToAdd.tablePosition === table.tablePosition) {
      return {
        ...tableToAdd,
        tablePosition: newPosition,
      };
    }
    return table;
  });
};

export const addTableInTables = (tables, tableToAdd) => {
  return [...tables, tableToAdd];
};

export const editTableInTables = (tables, tableToEdit) => {
  return tables.map((table) => {
    if (tableToEdit.tablePosition === table.tablePosition) {
      return tableToEdit;
    }
    return table;
  });
};

export const deleteTableInTables = (tables, tableToRemove) => {
  return tables.filter(
    (table) => tableToRemove.tablePosition !== table.tablePosition
  );
};
