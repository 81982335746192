import styled from "styled-components";

export const StyledNavigation = styled.nav`
  ul {
    display: flex;
  }
  li {
    padding: 5px 15px;
  }
`;
