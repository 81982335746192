import React from "react";

import { StyledDiv } from "./FormInput.styles";

const FormInput = ({ label, id, ...otherProps }) => {
  return (
    <StyledDiv>
      <label htmlFor={id}>{label}</label>
      <input id={id} name={id} {...otherProps} />
    </StyledDiv>
  );
};

export default FormInput;
