import React from "react";
import { StyledDiv } from "./DisplayGrid.styles";

const DisplayGrid = ({ rows, cols, className = "", children }) => {
  return (
    <StyledDiv $rows={rows} $cols={cols} className={className}>
      {children}
    </StyledDiv>
  );
};

export default DisplayGrid;
