import styled from "styled-components";

export const StyledDiv = styled.div`
  label {
    margin-right: 0.7em;
  }
  input {
    font-size: 1rem;
    padding: 0.5em 0.8em;
  }
`;
