export const hasArrayObjectAttributeValue = (
  array,
  objectAttributeName,
  value
) => {
  return array.some((item) => item[objectAttributeName] === value);
};

export const formatDate = (dateString) => {
  return new Date(dateString * 1000).toLocaleDateString("en-US");
};

export const formatTime = (dateString) => {
  return new Date(dateString * 1000).toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });
};
