import styled from "styled-components";

import Container from "../../reusable-components/container/Container";

export const StyledHeader = styled.header`
  padding: 10px;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
`;

export const StyledContainer = styled(Container)`
  display: flex;
  min-height: 64px;
  align-items: center;
  img {
    margin-right: 3rem;
  }
`;
