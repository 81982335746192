import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
  collection,
  doc,
  addDoc,
  getDoc,
  deleteDoc,
  updateDoc,
  getDocs,
  setDoc,
  getFirestore,
  serverTimestamp,
  where,
  query,
  orderBy,
  Timestamp,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const firestore = getFirestore(app);

export const createUserProfileDocument = async (userAuth, additionalData) => {
  if (!userAuth) return;

  const userRef = doc(firestore, `users/${userAuth.uid}`);
  const snapShot = await getDoc(userRef);

  if (!snapShot.exists()) {
    const { email } = userAuth;
    const { displayName } = additionalData;

    await setDoc(userRef, {
      displayName,
      email,
      timestamp: serverTimestamp(),
    });

    return userRef;
  }
};

export const updateField = async (document, documentId, field, data) => {
  const docRef = doc(firestore, `${document}/${documentId}`);
  const snapshot = await getDoc(docRef);

  if (snapshot.exists()) {
    await updateDoc(docRef, {
      [field]: data,
    });
  }
};

export const addTableDocument = async (user, table) => {
  if (!user) return;

  const tablesRef = collection(firestore, "tables");

  const tableId = await addDoc(tablesRef, {
    ...table,
    user: user.uid,
  });

  await updateField("tables", tableId.id, "id", tableId.id);

  return tableId.id;
};

export const getTables = async (userUid) => {
  if (!userUid) return;

  const tablesRef = collection(firestore, "tables");
  const q = query(tablesRef, where("user", "==", userUid));

  const querySnapshot = await getDocs(q);

  let tables = [];

  if (querySnapshot.size > 0) {
    querySnapshot.docs.map((doc) => {
      return tables.push({ id: doc.id, ...doc.data() });
    });
  }
  return tables;
};

export const deleteTableDoc = async (userUid, table) => {
  if (!userUid) return;

  const tableRef = doc(firestore, `tables/${table.id}`);
  await deleteDoc(tableRef);
};

export const updateTableDoc = async (userUid, table) => {
  if (!userUid) return;

  const tableRef = doc(firestore, `tables/${table.id}`);
  await setDoc(tableRef, table);
};

export const getReservationsByTable = async (userUid, tableId, past) => {
  if (!tableId) return;

  const now = new Date();
  const oldOrNew = past === "upcoming" ? ">=" : "<=";

  const reservationsRef = collection(firestore, "reservations");
  const q = query(
    reservationsRef,
    where("user", "==", userUid),
    where("tableId", "==", tableId),
    where("reservationDateTime", oldOrNew, now),
    orderBy("reservationDateTime")
  );

  const querySnapshot = await getDocs(q);
  let reservations = [];

  if (querySnapshot.size > 0) {
    querySnapshot.docs.map((doc) => {
      return reservations.push({ id: doc.id, ...doc.data() });
    });
  }

  return reservations;
};

export const getReservationsByDate = async (userId, date) => {
  const reservationsRef = collection(firestore, "reservations");

  const selectedDate = new Date(date);
  selectedDate.setDate(selectedDate.getDate() - 1);
  selectedDate.setHours(23, 59, 59);

  const nextDate = new Date(date);
  nextDate.setHours(23, 59, 59);

  const q = query(
    reservationsRef,
    where("reservationDateTime", ">=", selectedDate),
    where("reservationDateTime", "<=", nextDate),
    where("user", "==", userId)
  );

  const querySnapshot = await getDocs(q);
  let reservations = [];

  if (querySnapshot.size > 0) {
    querySnapshot.docs.map((doc) => {
      return reservations.push({ id: doc.id, ...doc.data() });
    });
  }

  return reservations;
};

export const isTimeslotAvailable = async (userUid, tableId, startDateTime) => {
  const reservationsRef = collection(firestore, "reservations");
  const q = query(
    reservationsRef,
    where("user", "==", userUid),
    where("tableId", "==", tableId)
  );
  const querySnapshot = await getDocs(q);
  const startDateTimeSeconds = Timestamp.fromDate(startDateTime).seconds;
  let bookedTimesSeconds = [];

  querySnapshot.docs.map((reservation) =>
    bookedTimesSeconds.push(reservation.data().reservationDateTime.seconds)
  );

  return bookedTimesSeconds.includes(startDateTimeSeconds) ? false : true;
};

export const addReservationDocument = async (reservation, tableId, userId) => {
  if (!reservation) return;

  const reservationRef = collection(firestore, "reservations");
  const reservationId = await addDoc(reservationRef, {
    ...reservation,
    tableId: tableId,
    user: userId,
  });

  return reservationId;
};

export const updateReservationDocument = async (reservation) => {
  if (!reservation) return;

  const reservationRef = doc(firestore, `reservations/${reservation.id}`);
  return await setDoc(reservationRef, {
    ...reservation,
  });
};

export const deleteReservationDocument = async (reservation) => {
  if (!reservation) return;

  const reservationRef = doc(firestore, `reservations/${reservation.id}`);
  return await deleteDoc(reservationRef, {
    ...reservation,
  });
};
