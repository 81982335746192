import React from "react";
import { StyledContainer } from "./errorBoundary.styles";
import CustomTitle from "../../reusable-components/custom-title/CustomTitle";
import Logo from "../../reusable-components/logo/Logo";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.log(error);
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <StyledContainer>
          <Logo />
          <CustomTitle>Something went wrong.</CustomTitle>
          <p>Please refresh the page and try again.</p>
        </StyledContainer>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
