import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import FormInput from "../form-input/FormInput";
import CustomButton from "../custom-button/CustomButton";
import CustomLink from "../custom-link/CustomLink";
import Message from "../message/Message";
import { useAuth } from "../../../hooks/useAuth";
import { authMessages } from "./../../../firebase/authMessages";
import CardBody from "../card-body/CardBody";

const Login = () => {
  const defaultFormData = {
    email: "",
    password: "",
    isLoading: false,
    error: "",
  };

  const [formData, setFormData] = useState(defaultFormData);
  const customAuth = useAuth();
  let history = useHistory();

  const { email, password, error } = formData;

  const handleChange = (event) => {
    const { value, name } = event.target;

    if (event.target.type === "checkbox") {
      setFormData({ ...formData, [name]: !formData[name] });
      return;
    }

    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await customAuth.signIn(email, password);

      history.push("/");
    } catch (error) {
      console.log({ error });
      const customMessage = authMessages[error.code];
      customAuth.changeSigningIn(false);

      setFormData({
        ...formData,
        error: customMessage ? customMessage : error.code,
      });
    }
  };

  return (
    <CardBody>
      <p>
        <strong>Sign in with your email and password</strong>
      </p>
      <form onSubmit={handleSubmit}>
        <FormInput
          onChange={handleChange}
          label="Email Address"
          name="email"
          type="email"
          id="email"
          value={formData.email}
          required
        />
        <FormInput
          onChange={handleChange}
          label="Password"
          name="password"
          type="password"
          id="password"
          value={formData.password}
          required
        />
        {error && <Message type="error">{error}</Message>}
        <CustomButton type="submit" isLoading={customAuth.signingIn}>
          Log In
        </CustomButton>
        <p>
          Not a member? <CustomLink to="/sign-up">Sign up</CustomLink> here.
        </p>
      </form>
    </CardBody>
  );
};

export default Login;
