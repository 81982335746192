import React from "react";
import { useDraggable } from "@dnd-kit/core";
import { StyledDiv } from "./Draggable.styles";

const Draggable = ({ isDragging, id, className, isStatic, children }) => {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: `draggable-${id}`,
    data: {
      tableNumber: id,
    },
  });

  return (
    <StyledDiv
      $isDragging={isDragging}
      $isStatic={isStatic}
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      className={className}>
      {children}
    </StyledDiv>
  );
};

export default Draggable;
