import styled from "styled-components";

export const StyledCard = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
`;

export const StyledCardHeader = styled.div`
  padding: 1rem;
  background-color: ${(props) => props.theme.colors.lightGrey};
  border-bottom: 1px solid ${(props) => props.theme.colors.grey};
`;

export const StyledCardBody = styled.div`
  padding: 1rem;
  min-height: 200px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 8%);
`;
