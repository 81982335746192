import {
  useState,
  useEffect,
  useContext,
  createContext,
  useCallback,
} from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { auth, firestore, updateField } from "../firebase/firebase.utils";
import { doc, getDoc, setDoc, serverTimestamp } from "firebase/firestore";

const authContext = createContext({ user: null });
const { Provider } = authContext;

export const AuthProvider = ({ children }) => {
  const customAuth = useAuthProvider();
  return <Provider value={customAuth}>{children}</Provider>;
};

export const useAuth = () => {
  return useContext(authContext);
};

export const useAuthProvider = () => {
  const [user, setUser] = useState(null);
  const [loadingAuthState, setLoadingAuthState] = useState(true);
  const [signingIn, setSigningIn] = useState(false);

  const handleAuthStateChanged = useCallback(
    async (user) => {
      if (!user) {
        setLoadingAuthState(false);
        return;
      }

      if (signingIn) return;
      const userAdditionalData = await getUserAdditionalData(user);

      setUser({ ...user, ...userAdditionalData });
      setLoadingAuthState(false);
    },
    [signingIn]
  );

  useEffect(() => {
    const unsub = auth.onAuthStateChanged(handleAuthStateChanged);

    return () => unsub();
  }, [handleAuthStateChanged]);

  const createUserAdditionalData = async (userObj, displayName) => {
    if (!userObj) return;

    const { email } = userObj;

    const userRef = doc(firestore, `users/${userObj.uid}`);
    return await setDoc(userRef, {
      displayName,
      email,
      restaurants: [],
      dateCreated: serverTimestamp(),
    });
  };

  const signUp = async (email, password, displayName) => {
    const userResponse = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    //   await sendEmailVerification(auth.currentUser);

    await createUserAdditionalData(userResponse.user, displayName);

    setUser({ ...userResponse.user, displayName, restaurants: [] });

    setLoadingAuthState(false);
  };

  const addRestaurantName = async (restaurantName) => {
    const restaurants = [...user.restaurants, restaurantName];
    await updateField("users", user.uid, "restaurants", restaurants);

    setUser({
      ...user,
      restaurants,
    });
  };

  const signIn = async (email, password) => {
    setSigningIn(true);
    const userResponse = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    const userAdditionalData = await getUserAdditionalData(userResponse.user);

    setUser({ ...userResponse.user, ...userAdditionalData });
    setSigningIn(false);
  };

  const logOut = async () => {
    await signOut(auth);
    setUser(null);
  };

  const getUserAdditionalData = async (user) => {
    const userRef = doc(firestore, `users/${user.uid}`);
    const snapShot = await getDoc(userRef);

    if (snapShot.exists()) {
      const userData = await getDoc(userRef);

      return userData.data();
    }
  };

  const changeSigningIn = (status) => {
    setSigningIn(status);
  };

  return {
    user,
    addRestaurantName,
    loadingAuthState,
    signingIn,
    changeSigningIn,
    signUp,
    signIn,
    logOut,
  };
};
